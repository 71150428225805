// GENERATE DEFAULT SKIN STYLES
@require "../../common/css/mixins";
generate-legacy-skin($nrk-color-cool-pink-800);
@require "../../common/css/skin-colors/purple-skin";

// OVERRIDES
.flow-content > .col > .widget.reference > a {
	color: $nrk-color-cool-pink-800;
}

.menu a.skin-text {
	color: white;
}

@media screen and (min-width: 720px) {
	.menu a.skin-text {
		color: $nrk-color-purple-900;
	}
}
